<template>
  <button
    type="button"
    :style="'width:' + largura"
    id="btn-red"
    :title="btnText"
  >
    {{ btnText }}
  </button>
</template>
<script>
export default {
  props: {
    btnText: { type: String, required: true },
    largura: { type: Number, required: true },
  },
  setup() {},
};
</script>
<style scoped>
#btn-red {
  font-weight: 700;
  padding: 10px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #d2ae6a;
  border: solid 1px #d2ae6a;
  transition: 0.3s;
  color: #000;
}

#btn-red:hover {
  background: #0d0d0d;
  border: solid 1px #d2ae6a;
  color: #d2ae6a;
}

@media (min-width: 992px) and (max-width: 1200px) {
  #btn-red {
    font-weight: 700;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #f0f0f0;
    border: solid 1px #f0f0f0;
    transition: 0.3s;
    color: #000;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 576px) and (max-width: 767px) {
}

@media (max-width: 575px) {
}
</style>