<template>
  <div>
    <div class="div_topo">
      <div class="container">
        <div class="row">
          <div class="col-3 col-sm-8 topo_esq">
            <a href="https://www.facebook.com/crossboxctu" target="_blank">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/boxctu_umuarama" target="_blank">
              <i class="bi bi-instagram"></i>
            </a>

            <span class="d-none d-md-inline"
              >Bem vindo ao Cross CTU! Venha desafiar seus limites</span
            >
          </div>
          <div
            class="col-9 col-sm-4"
            style="color: #000; font-weight: bold; text-align: right"
          >
            <i class="bi bi-whatsapp"></i> (44) 9 9908-7982
          </div>
        </div>
      </div>
    </div>
    <section id="page">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="div_logo">
              <img
                id="img_top"
                src="./../../imagens/logo 1.png"
                alt="Logo Box Ctu"
              />
            </div>
            <div class="conteudo">
              <p class="subtitle">Aumente a sua qualidade de vida</p>
              <h1>Mais que um simples Box de Cross, um estilo de vida!</h1>
              <p class="desc">
                O destino definitivo para uma experiência completa de
                condicionamento físico! Supere seus limites com o Cross.
              </p>
              <div class="div_buttons">
                <a href="#aqui">
                  <btn :btnText="'SAIBA MAIS'" :largura="'200px'" />
                </a>
                <a
                  target="_blank"
                  href="https://wa.me/554499087982?text=Olá! Vim através do site da CTU para ter uma experiência na minha primeira aula experimental.

    Quero participar das aulas de Cross e fazer o agendamento da aula."
                >
                  <button
                    type="button"
                    class="btn-whatsapp mt-3 mt-sm-0"
                    title="Fale Conosco"
                  >
                    <i class="bi bi-whatsapp"></i> Entrar em contato
                  </button></a
                >
              </div>
              <div class="marcadores">
                <p>
                  <i class="bi bi-currency-dollar"></i
                  ><span class="span_p"
                    >Preço referente pra quem quer resultados</span
                  >
                </p>
                <p>
                  <i class="bi bi-person-check-fill"></i
                  ><span class="span_p">Coach 100% técnico nas aulas</span>
                </p>
                <p>
                  <i class="bi bi-clipboard2"></i
                  ><span class="span_p"
                    >Aulas de Levantamento de peso olímpico, Ginástica,
                    Corrida</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="div_img_barra">
              <img
                class="homen_barra"
                alt="Homen na bara"
                src="./../../imagens/barra2.png"
              />
            </div>
          </div>
        </div>
      </div>
      <section id="sct_quem_somos">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="conteudo2">
                <p class="subtitle">quem somos</p>
                <h3>
                  Na CTU, não somos apenas uma academia de Cross, somos uma
                  comunidade apaixonada pela saúde, condicionamento físico e
                  superação de limites. Nossa missão é proporcionar um ambiente
                  inspirador, onde pessoas de todos os níveis de condicionamento
                  físico se sintam capacitadas a alcançar seus objetivos e
                  transformar suas vidas.
                </h3>
              </div>
            </div>
            <!-- <div class="col-md-5 offset-md-2">
                <div class="conteudo3">
                  <p>Feugiat consequat netus in cras sapien. Nec pharetra nibh turpis fringilla quam scelerisque. Accumsan
                    non
                    mauris mus sodales vehicula. Tortor, urna at eget arcu eu. Sit in gravida dui, vitae fermentum
                    phasellus
                    velit, leo, phasellus. Gravida consequat aliquet urna, mollis tincidunt amet sed tincidunt eget. </p>
                  <p class="p_strong">Feugiat consequat netus in cras sapien. Nec pharetra nibh turpis fringilla quam
                    scelerisque.</p>
                  <div class="div_a_zap">
                    <a type="button" class="a-whatsapp" title="Fale Conosco"> <img class="avatar"
                        src="./../../imagens/avatare.png" alt="Avatar " /> Fale com a gente agora <i
                        class="bi bi-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </section>
      <div class="container">
        <section id="sessao_beneficios">
          <div id="margin2">
            <h3 id="aqui">Alguns benefícios do Cross</h3>
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="quad_branco">
                  <div style="text-align: center; height: 100px">
                    <img
                      src="./../../imagens/ex1.png"
                      style="width: 100px; margin-top: 20px"
                    />
                  </div>

                  <h2>Trabalho realizado para o aluno sair do sedentarismo</h2>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="quad_branco">
                  <div style="text-align: center; height: 100px">
                    <img src="./../../imagens/ex2.png" style="width: 50px" />
                  </div>
                  <h2>Crie hábitos mais saudáveis</h2>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="quad_branco">
                  <div style="text-align: center; height: 100px">
                    <img src="./../../imagens/ex3.png" style="width: 30px" />
                  </div>

                  <h2>Promover outras atividades de alto gasto calórico</h2>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="quad_branco">
                  <div style="text-align: center; height: 100px">
                    <img
                      src="./../../imagens/ex4.png"
                      style="width: 100px; margin-top: 25px"
                    />
                  </div>

                  <h2>Fortalecimento do grupo como uma comunidade</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="div_img_ctu">
                    <img
                      alt="Foto Academia"
                      src="./../../imagens/Imagem do WhatsApp de 2024-01-17 à(s) 11.45 1.png"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="div_img_ctu">
                    <img
                      alt="Foto Entrada"
                      src="./../../imagens/Imagem do WhatsApp de 2024-01-17 à(s) 11.45 2.png"
                    />
                  </div>
                </div>
              </div>
              <div class="img-principal">
                <div class="div_img_ctu_principal">
                  <img
                    alt="Foto Academia"
                    src="./../../imagens/Imagem do WhatsApp de 2024-01-17 à(s) 11.45 3.png"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1">
              <div class="conteudo4">
                <p class="subtitle">Solução para você</p>
                <h2>
                  Nossa academia oferece tudo o que você precisa para um ótimo
                  treino.
                </h2>
                <!-- <p class="desc">
                Sit in gravida dui, vitae fermentum phasellus velit, leo, phasellus. Gravida consequat aliquet urna,
                mollis tincidunt amet sed tincidunt eget. Magna augue est turpis eu id condimentum purus.
              </p> -->
              </div>
              <div class="conteudo5">
                <div class="opcao">
                  <!--  <div class="circle"><i class="bi bi-snow"></i></div> -->
                  <img src="./../../imagens/querobel.png" style="width: 30px" />
                  <div class="div-text">
                    <h3>Ambiente Agradável</h3>
                  </div>
                </div>
                <div class="opcao">
                  <div class="">
                    <img
                      src="./../../imagens/querobel.png"
                      style="width: 30px"
                    />
                  </div>
                  <div class="div-text">
                    <h3>
                      Todos equipamentos necessários para prática do cross
                    </h3>
                  </div>
                </div>
                <div class="opcao">
                  <div class="">
                    <img
                      src="./../../imagens/querobel.png"
                      style="width: 30px"
                    />
                  </div>
                  <div class="div-text">
                    <h3>Alerta sobre a programação</h3>
                  </div>
                </div>
                <div class="opcao">
                  <div class="">
                    <img
                      src="./../../imagens/querobel.png"
                      style="width: 30px"
                    />
                  </div>
                  <div class="div-text">
                    <h3>Coach treinado e capacitado</h3>
                  </div>
                </div>
                <div class="opcao">
                  <div class="">
                    <img
                      src="./../../imagens/querobel.png"
                      style="width: 30px"
                    />
                  </div>
                  <div class="div-text">
                    <h3>Aplicativo para auxilio do aluno</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="quests">
          <div class="container-fluid">
            <div class="title-quest">
              <h2>Perguntas frequentes sobre o Cross:</h2>
            </div>
          </div>
          <div class="container mt-5">
            <div @click="toggleSection(0)" class="question-answer">
              <h5 class="question">
                Por que não enviam o preço ?
                <i
                  :class="
                    expandedSection === 0
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 0">
                <p class="response">
                  Sabemos o custo pra fazer com que você MUDE DE VIDA, e saia do
                  sedentarismo. Através de ajustes, você irá perceber que o
                  investimento é prioridade na sua vida. OU VOCÊ SERÁ SEMPRE A
                  PESSOA QUE COMEÇA E DESISTE?
                </p>
              </Collapse>
            </div>
            <div @click="toggleSection(1)" class="question-answer">
              <h5 class="question">
                Preciso realmente fazer a aula experimental?
                <i
                  :class="
                    expandedSection === 1
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 1">
                <p class="response">
                  Sim, sem essa aula, não existe possibilidade de começar dentro
                  do Esporte, VOCÊ PRECISA SE AMBIENTAR!
                </p>
              </Collapse>
            </div>
            <div @click="toggleSection(2)" class="question-answer">
              <h5 class="question">
                Nao gosto de musculação, o que fazer?
                <i
                  :class="
                    expandedSection === 2
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 2">
                <p class="response">
                  Cerca de 40% dos alunos de uma turma do Cross vieram da
                  musculação . O motivo é que era sempre treinos monótonos e
                  repetitivos, sem contar a atenção que tinham, que era de 0%! A
                  não ser que você pagasse um perdonal, e olhe lá ! O cross é em
                  grupo, com treinos diferentes, é um grupo acolhedor e
                  humanizado, isso é nosso diferencial
                </p>
              </Collapse>
            </div>
            <div @click="toggleSection(3)" class="question-answer">
              <h5 class="question">
                O risco de lesão no cross é maior?
                <i
                  :class="
                    expandedSection === 3
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 3">
                <p class="response">
                  O Risco de se lesionar durante uma aula é o mesmo que em
                  qualquer modalidade, sobretudo quando praticada sem orientação
                  profissional pode acontecer de você se lesionar em algum
                  exercício. Mas o risco não é inevitável como muitos dizem por
                  aí.
                </p>
              </Collapse>
            </div>
            <div @click="toggleSection(4)" class="question-answer">
              <h5 class="question">
                Qualquer um pode praticar cross?
                <i
                  :class="
                    expandedSection === 4
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 4">
                <p class="response">
                  Assim como outros esportes, qualquer um pode praticar, mas
                  sempre respeitando seus limites.
                </p>
              </Collapse>
            </div>
            <div @click="toggleSection(5)" class="question-answer">
              <h5 class="question">
                O cross traz benefícios para a saúde?
                <i
                  :class="
                    expandedSection === 5
                      ? 'bi bi-chevron-up'
                      : 'bi bi-chevron-down'
                  "
                  class="arrow-icon"
                ></i>
              </h5>
              <Collapse :when="expandedSection === 5">
                <p class="response">
                  A prática do Cross auxilia na melhora do condicionamento
                  físico e cardiorrespiratório além de prover ganho de massa
                  magra e perda de peso.
                </p>
              </Collapse>
            </div>
          </div>
        </section>

        <div id="margin2">
          <div class="row">
            <div class="col-md-4">
              <div class="conteudo6">
                <p class="subtitle">venha conhecer</p>
                <h2>Garanta já sua experiência em nossa academia</h2>
                <div>
                  <a
                    target="_blank"
                    href="https://wa.me/554499087982?text=Olá! Vim através do site da CTU para ter uma experiência na minha primeira aula experimental.

                  Quero participar das aulas de Cross e fazer o agendamento da aula."
                  >
                    <btn :btnText="'ENTRE EM CONTATO'" :largura="'280px'" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 offset-md-2">
              <div class="div_img_barra2">
                <img
                  class="img_barra_2"
                  src="./../../imagens/halter1.png"
                  alt="Homen segurando a barra"
                />
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div class="div_flex">
            <div class="div_img_logo_footer">
              <a href="#page">
                <img src="./../../imagens/logo 1.png" alt="Logo Box Ctu"
              /></a>
            </div>
            <div class="copy">
              <p>Copyright © 2024 CTU. Todos os direitos reservados.</p>
            </div>
            <div class="icons">
              <a
                target="_blank"
                href="https://www.instagram.com/boxctu_umuarama/"
              >
                <i class="bi bi-instagram"></i
              ></a>
              <a
                target="_blank"
                href="https://www.facebook.com/crossboxctu/?locale=pt_BR"
                ><i class="bi bi-facebook"></i
              ></a>
            </div>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from "vue";
import { Collapse } from "vue-collapsed";
import btn from "../components/button.vue";
export default {
  components: {
    Collapse,
    btn,
  },
  setup() {
    const expandedSection = ref(null);

    const toggleSection = (sectionIndex) => {
      expandedSection.value =
        expandedSection.value === sectionIndex ? null : sectionIndex;
    };

    return {
      expandedSection,
      toggleSection,
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;500;700&display=swap");
</style>
<style scoped>
.arrow-icon {
  float: right;
  transition: transform 0.3s;
}

#sct_quem_somos {
  background-color: #161616;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
}

#sessao_beneficios {
}

.div_topo {
  padding: 7px;
  background-color: #d2ae6a;
}

.div_topo .topo_esq {
  color: #000;
  font-weight: bold;
}

.div_topo .topo_esq a {
  color: #000;
}

.div_topo i {
  margin-right: 5px;
}

#page {
  background: #0d0d0d;
}

#margin {
  margin-top: 8rem;
}

#margin2 {
  margin-top: 8rem;
  margin-bottom: 6rem;
}

#margin3 {
  margin-top: 8rem;
  padding-bottom: 6rem;
}

.homen_barra {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.conteudo .subtitle {
  color: #d2ae6a;
  margin-top: 20px;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.conteudo h1 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 55px;
  font-weight: 600;
  line-height: 1;
}

.conteudo .desc {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
}

.btn-whatsapp {
  color: #f0f0f0;
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 700;
  background: transparent;
  border: none;
  padding: 10px 48px;
  width: auto;
  border-radius: 6px;
  margin-left: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.btn-whatsapp:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px #f0f0f0;
}

.marcadores {
  margin-top: 4rem;
}

.marcadores p {
  color: #fafafa;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.marcadores .span_p {
  margin-left: 10px;
}

.div_logo {
  text-align: center;
  margin-top: 1rem;
}

.div_logo img {
  width: 200px;
}

.conteudo2 .subtitle {
  color: #d2ae6a;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.conteudo2 h3 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

#aqui {
  color: #d2ae6a;
  font-family: Archivo;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.conteudo3 {
  margin-top: 2rem;
  color: #d5d5d5;
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

.conteudo3 .p_strong {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
}

.a-whatsapp {
  color: #e13229;
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  border: none;
  width: auto;
  text-decoration: none;
}

.div_a_zap {
  margin-top: 20px;
}

.quad_branco {
  border: #d2ae6a solid 6px;
  border-radius: 12px;
  background: #f0f0f0;
  box-shadow: 0px 24px 32px 0px rgba(205, 208, 231, 0.3);
  width: 100%;
  min-height: 270px;
  padding: 30px;
}

.quad_branco h2 {
  color: #2a2739;
  font-family: Archivo;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-top: 20px;
}

.quad_branco p {
  color: #757385;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.div_img_ctu img {
  width: 100%;
}

.div_img_ctu_principal {
  margin-top: 1rem;
}

.div_img_ctu_principal img {
  width: 100%;
}

.conteudo4 .subtitle {
  color: #d2ae6a;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.conteudo4 {
  margin-bottom: 4rem;
}

.conteudo4 h2 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 2rem;
}

.conteudo4 .desc {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.opcao {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.circle {
  color: #e13229;
  width: 50px;
  height: 50px;
  background: #f2f5fc;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.opcao h3 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

hr {
  color: #cecdd9;
}

.div-text {
  margin-left: 1rem;
}

.div-text p {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.div_img_barra2 {
  text-align: right;
}

.img_barra_2 {
  width: 500px;
}

.conteudo6 .subtitle {
  color: #d2ae6a;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}

.conteudo6 h2 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.conteudo6 {
  color: #f0f0f0;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.div_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icons i {
  color: #f0f0f0;
  font-size: 20pt;
  margin: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.icons i:hover {
  filter: drop-shadow(0 0 0.8rem #f0f0f0);
}

.div_img_logo_footer img {
  width: 50px;
}

footer {
  padding: 1rem;
}

.copy p {
  color: #f0f0f0;
  margin-bottom: 0;
  font-size: 12pt;
  font-weight: 400;
}

#quests {
  margin-top: 20px;
}

.title-quest {
  text-align: center;
}

.title-quest h2 {
  color: #fff;
  font-weight: 900;
}

.question-answer {
  padding: 20px;
  border-top: 1px solid #fff;
  cursor: pointer;
}

.question {
  font-weight: bold;
  color: #d2ae6a;
  margin-bottom: 0;
}

.response {
  margin-top: 10px;
  color: #f0f0f0;
  font-weight: 500;
}

@media (min-width: 576px) and (max-width: 767px) {
  .quad_branco {
    min-height: 250px;
    height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .quad_branco {
    min-height: 200px;
    height: 200px;
  }

  .img_barra_2 {
    width: 300px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .quad_branco {
    height: 280px;
  }
}

@media (max-width: 575px) {
  .div_logo img {
    width: 150px;
  }

  .conteudo {
    margin-top: 1rem;
  }

  .conteudo h1 {
    font-size: 40px;
  }

  .div_buttons {
    text-align: center;
  }

  .div_buttons a {
    display: block;
  }

  /*
  .div_buttons button,
  .div_buttons a {
    width: 45%;
  }*/

  .div_img_barra {
    display: none;
  }

  #margin {
    margin-top: 3rem;
  }

  .conteudo3 {
    margin-top: 10px;
  }

  #margin2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .quad_branco {
    margin-top: 30px;
    text-align: center;
    padding: 20px;
  }

  .div_img_ctu {
    margin-top: 15px;
  }

  .conteudo4 {
    margin-top: 2rem;
  }

  .div_img_barra2 {
    display: none;
  }

  #margin3 {
    margin-top: 4rem;
  }

  .btn-whatsapp {
    padding: 10px 25px;
    margin-left: 0;
  }

  .opcao h3 {
    font-size: 12px;
  }

  .opcao p {
    font-size: 12px;
  }

  .circle {
    width: 40px;
    height: 40px;
    font-size: 12px;
  }

  .copy p {
    text-align: center;
  }

  .question {
    font-size: 11pt;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .div_logo img {
    width: 150px;
  }

  .conteudo {
    margin-top: 1rem;
  }

  .conteudo h1 {
    font-size: 45px;
  }

  .div_img_barra {
    display: none;
  }

  #margin {
    margin-top: 3rem;
  }

  .conteudo3 {
    margin-top: 10px;
  }

  #margin2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .quad_branco {
    margin-top: 30px;
  }

  .conteudo4 {
    margin-top: 2rem;
  }

  .div_img_barra2 {
    display: none;
  }

  #margin3 {
    margin-top: 4rem;
  }

  .copy p {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .div_logo img {
    width: 120px;
    margin-bottom: 1rem;
  }

  .conteudo h1 {
    font-size: 29px;
  }

  .conteudo .desc {
    font-size: 18px;
  }

  .btn-whatsapp {
    padding: 10px 25px;
    margin-left: 0;
  }

  .quad_branco {
    padding: 10px;
    text-align: center;
  }

  .quad_branco h2 {
    font-size: 18px;
  }

  .conteudo4 {
    margin-top: 4rem;
  }

  .conteudo6 {
    font-size: 16px;
  }

  .conteudo6 h2 {
    font-size: 27px;
  }

  #margin2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  #margin3 {
    margin-top: 4rem;
  }

  .div_img_barra {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .conteudo h1 {
    font-size: 35px;
  }

  .conteudo .desc {
    font-size: 18px;
  }

  .btn-whatsapp {
    margin-left: 5px;
  }

  .opcao h3 {
    font-size: 17px;
  }

  .conteudo2 h2 {
    font-size: 28px;
  }

  .conteudo4 {
    margin-bottom: 1rem;
  }

  .conteudo4 h2 {
    font-size: 26px;
  }

  .div-text padding {
    font-size: 15px;
  }

  .conteudo6 {
    font-size: 16px;
  }

  .conteudo6 h2 {
    font-size: 28px;
  }

  .div_img_barra {
    display: block;
  }

  .img_barra_2 {
    width: 450px;
  }
}
</style>
